import * as React from 'react';
import { PageProps, navigate } from 'gatsby';
import { gql } from '@urql/core';
import qs from 'query-string';
import NProgress from 'nprogress';
import { CatalogLayout } from 'components/CatalogLayout';
import { PageError } from 'components/PageError';
import { BackButton } from 'components/BackButton';
import searchEmptyImg from 'images/search-empty.png';
import { useSearchPageQuery } from 'generated/graphql';
import { ProductCardListSkeleton, ProductListWrapper } from 'components/ProductCardList';
import { ProductCard } from 'components/ProductCard';

type WrapperTypes = {
  searchTerm: string;
  query: string;
  productCount?: number | null;
  onChange: (val: string) => void;
};

const ContentWrapper: React.FC<WrapperTypes> = ({ children, searchTerm, query, productCount, onChange }) => (
  <CatalogLayout>
    <input
      value={searchTerm}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Поиск по каталогу"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      className="text-black placeholder-black bg-gray-600 px-4 py-2 rounded-md border border-transparent transition-colors duration-300 ease-in-out mt-4 mb-6 focus:border-cyan-300"
    />
    <div className="w-full">
      <BackButton className="font-medium text-2xl mb-5">
        {query} <>{productCount && <>({productCount})</>}</>
      </BackButton>
      {children}
    </div>
  </CatalogLayout>
);

const Search = ({ location }: PageProps) => {
  const queryParams = qs.parse(location.search);
  const query = typeof queryParams.q === 'string' ? queryParams.q : '';

  const [queryRes, reexecuteQuery] = useSearchPageQuery({
    variables: { query },
    pause: !query,
    requestPolicy: 'cache-and-network',
  });

  const [searchTerm, setSearchTerm] = React.useState(query);

  React.useEffect(() => {
    if (!searchTerm.trim()) return undefined;

    const t = setTimeout(() => {
      navigate(`/search?q=${searchTerm}`, { replace: true });
    }, 2000);

    return () => clearTimeout(t);
  }, [searchTerm]);

  if (queryRes.error) {
    const retry = () => reexecuteQuery({ requestPolicy: 'network-only' });
    return (
      <CatalogLayout>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </CatalogLayout>
    );
  }

  if (queryRes.fetching) {
    NProgress.start();
  } else {
    NProgress.done();
  }

  const onChange = (val: string) => setSearchTerm(val);

  if (queryRes.fetching) {
    return (
      <ContentWrapper searchTerm={searchTerm} query={query} onChange={onChange}>
        <ProductCardListSkeleton />
      </ContentWrapper>
    );
  }

  const results = queryRes.data?.search || [];

  const products = results.map((p) => {
    if (p.__typename !== 'Product') return null;

    return <ProductCard key={p.id} data={p} />;
  });

  return (
    <ContentWrapper searchTerm={searchTerm} query={query} productCount={products.length || null} onChange={onChange}>
      {products.length > 0 ? (
        <ProductListWrapper>{products}</ProductListWrapper>
      ) : (
        <div className="flex flex-1 flex-col justify-center items-center w-full">
          <img src={searchEmptyImg} alt="" />
          <p className="text-center text-2xl font-medium mt-5">
            Извините, по вашему запросу <br />
            ничего не найдено
          </p>
        </div>
      )}
    </ContentWrapper>
  );
};

export default Search;

gql`
  query SearchPageQuery($query: String!) {
    search(query: $query) {
      ... on Product {
        id
        ...ProductCard_product
      }
    }
  }
`;
